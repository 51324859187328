<template>
  <div class="billing-overview card m-5 px-5 pt-4">
    <span class="billing-overview__arrow-left-icon mb-4">
      <ArrowLeftIcon class="mr-2" />
      Back
    </span>
    <div class="d-flex justify-content-between mt-4">
      <div>
        <p class="billing-overview__title">Date</p>
        <h5 class="mt-2">10 Oct, 2021</h5>
      </div>
      <div>
        <p class="billing-overview__title">Bank</p>
        <h5 class="mt-2">GTBank</h5>
      </div>
      <div>
        <p class="billing-overview__title">Amount earned</p>
        <h5 class="mt-2">$3,000</h5>
      </div>
    </div>
    <div class="mt-4">
      <p class="billing-overview__title">Status</p>
    </div>
    <div class="mt-4">
      <p class="billing-overview__title mb-2">Caption</p>
      <h5>This Payment is for the month of October</h5>
    </div>
    <b-button
      class="billing-overview__download-btn  w-50 mx-auto"
      variant="primary"
    >
      <DownloadIcon class="mr-3" />
      Download Pay Slip
    </b-button>
  </div>
</template>
<script>
import ArrowLeftIcon from "../../components/svg/ArrowLeftIcon.vue";
import DownloadIcon from "../../components/svg/DownloadIcon.vue";
export default {
  name: "Payslip",
  components: { ArrowLeftIcon, DownloadIcon },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.billing-overview {
  padding-bottom: 6rem;
  border: none;
  border-radius: 10px;
  &__download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    padding: 2rem 0;
    margin-top: 4rem;
  }
  &__arrow-left-icon {
    color: $faint-blue;
    font-weight: 600;
    font-size: 12px;
  }
  &__title {
    color: $grey-tint-2;
    font-weight: 600;
    font-size: 12px;
  }
}
</style>
