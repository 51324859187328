<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.17 17.5699C19.64 18.0599 19.03 18.4299 18.37 18.6799C17.71 18.9299 17 18.4499 17 17.7399V16.4199C17 14.4699 15.42 12.8899 13.47 12.8899H10.53C8.58 12.8899 7 14.4699 7 16.4199V17.9999C7 18.5499 6.55 18.9999 6 18.9999H5.55C3.1 18.6299 2 16.6399 2 14.8599C2 13.1799 2.98 11.3199 5.11 10.7999C4.52 8.46994 5.02 6.27994 6.54 4.68994C8.27 2.87994 11.03 2.15994 13.41 2.89994C15.6 3.56994 17.14 5.36994 17.69 7.86994C19.6 8.29994 21.13 9.73994 21.74 11.7399C22.4 13.9099 21.8 16.1399 20.17 17.5699Z"
      fill="#5DAEF8"
    />
    <path
      d="M13.47 14.3896H10.53C9.26 14.3896 8.5 15.1496 8.5 16.4196V19.3596C8.5 20.6296 9.26 21.3896 10.53 21.3896H13.47C14.74 21.3896 15.5 20.6296 15.5 19.3596V16.4196C15.5 15.1496 14.74 14.3896 13.47 14.3896ZM13.67 18.0496L12 19.9496L11.8 20.1796C11.53 20.4896 11.3 20.4096 11.3 19.9896V18.2096H10.54C10.19 18.2096 10.1 17.9996 10.33 17.7396L12 15.8396L12.2 15.6096C12.47 15.2996 12.7 15.3796 12.7 15.7996V17.5796H13.46C13.81 17.5696 13.9 17.7896 13.67 18.0496Z"
      fill="#5DAEF8"
    />
  </svg>
</template>
<script>
export default {
    name: "DownloadIcon"
}
</script>